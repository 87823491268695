import { isDevelopment } from "../dev";

export const _url = process.env.REACT_APP_URL || "https://homoludens.ru";
export const _URL: string = isDevelopment ? _url : "";
export const _URLfix: string = _url;

export const insertQuery = (url: string, params: string) =>
  `${url}${url?.includes("?") ? "&" : "?"}${params}`;

export const insertHash = (url: string, params: string) =>
  `${url}${url?.includes("#") ? "&" : "#"}${params}`;
