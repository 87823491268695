type RouteType = {
	path: string | string[]
	exact?: boolean
	component: any
}

export const createRouter = (...routes: (RouteType[] | RouteType)[]) => {
	return routes
		.map(x => (Array.isArray(x) ? x : [x]))
		.reduce((a, c) => a.concat(c), [])
}
