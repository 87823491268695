import { createEvent, sample, createEffect } from "effector";
import { AnyObject } from "../types";
import { $historyPath, history } from "./history";
import { withHash } from "../utils/url/with-hash";
import { withParams } from "../utils/url";

type LocationType =
  | string
  | {
      pathname?: string;
      search?: string | AnyObject;
      state?: AnyObject;
      hash?: string | AnyObject;
      key?: string;
    };

const changeHistory = createEvent<LocationType>();
const changeHistoryFx = createEffect<LocationType, void>({
  handler: (p) =>
    history.push(
      typeof p === "string"
        ? p
        : {
            ...p,
            search: p.search
              ? typeof p.search === "string"
                ? p.search
                : withParams("", p.search)
              : undefined,
            hash: p.hash
              ? typeof p.hash === "string"
                ? p.hash
                : withHash("", p.hash)
              : undefined,
          }
    ),
});

const replaceSearch = createEvent<AnyObject>();
const replaceSearchSample = sample({
  source: $historyPath,
  clock: replaceSearch,
  fn: ({ pathname, hashString }, search) => ({
    pathname,
    search: withParams("", search),
    hash: hashString,
  }),
});

const appendSearch = createEvent<AnyObject>();
const appendSearchSample = sample({
  source: $historyPath,
  clock: appendSearch,
  fn: ({ pathname, search, hashString }, params) => ({
    pathname,
    search: withParams({ ...search, ...params })(""),
    hash: hashString,
  }),
});

const removeSearch = createEvent<string[]>();
const removeSearchSample = sample({
  source: $historyPath,
  clock: removeSearch,
  fn: ({ pathname, search, hashString }, params) => {
    const obj = { ...search };
    params.forEach((x) => {
      delete obj[x];
    });
    return {
      pathname,
      search: withParams(obj)(""),
      hash: hashString,
    };
  },
});

const changeLocation = createEvent<string>();

const changePath = createEvent<string>();
const changePathSample = sample({
  source: $historyPath,
  clock: changePath,
  fn: ({ searchString, hashString }, pathname) => {
    return {
      pathname,
      hash: hashString,
      search: searchString,
    };
  },
});

const removeHash = createEvent<string[]>();
const removeHashSample = sample({
  source: $historyPath,
  clock: removeHash,
  fn: ({ pathname, hash, searchString }, params) => {
    const obj = { ...hash };
    params.forEach((x) => {
      delete obj[x];
    });
    return {
      pathname,
      hash: withHash(obj)(""),
      search: searchString,
    };
  },
});

const replaceHash = createEvent<AnyObject>();
const replaceHashSample = sample({
  source: $historyPath,
  clock: replaceHash,
  fn: ({ pathname, searchString }, hash) => ({
    pathname,
    search: searchString,
    hash: withHash("", hash),
  }),
});

const appendHash = createEvent<AnyObject>();
const appendHashSample = sample({
  source: $historyPath,
  clock: appendHash,
  fn: ({ pathname, searchString, hash }, params) => ({
    pathname,
    hash: withHash({ ...hash, ...params })(""),
    search: searchString,
  }),
});

sample({
  clock: [
    changeHistory,
    replaceSearchSample,
    appendSearchSample,
    removeSearchSample,
    changeLocation.map((x) => ({ pathname: x })),
    removeHashSample,
    replaceHashSample,
    appendHashSample,
    changePathSample,
  ],
  target: changeHistoryFx,
});

export const historyMethods = {
  changeHistory,
  replaceSearch,
  appendSearch,
  removeSearch,
  changeLocation,
  removeHash,
  replaceHash,
  appendHash,
  changePath,
};
