import { sections } from "config/sections";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { NavItemProps } from "./types/header";
import { media } from "libs/styles/media";

export const Header = () => {
  const [activeSection, setActiveSection] = useState<string | null>(null);

  useEffect(() => {
    const observerOptions = {
      rootMargin: "0px",
      threshold: 0.6,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, observerOptions);

    const timeoutId = setTimeout(() => {
      sections.forEach((sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          observer.observe(sectionElement);
        }
      });
    }, 100);

    return () => {
      clearTimeout(timeoutId);
      sections.forEach((sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          observer.unobserve(sectionElement);
        }
      });
    };
  }, [sections]);

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    sectionId: string
  ) => {
    event.preventDefault();
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      window.scrollTo({
        top: sectionElement.offsetTop - 60,
        behavior: "smooth",
      });
    }
  };

  return (
    <HeaderContainer>
      {sections.map((section) => (
        <NavItem
          key={section}
          active={activeSection === section}
          onClick={(event) => handleClick(event, section)}
        >
          {section}
        </NavItem>
      ))}
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: calc(1.25rem * 2);
  height: auto;
  background-color: black;
  display: flex;
  justify-content: center;
  z-index: 1000;
  transition: all 0.3s ease;

  ${media.pure.less(media.size.sm)} {
    width: 100%;
    left: 0;
    height: 30px;
  }
`;

const NavItem = styled.a<NavItemProps>`
  color: ${({ active }) => (active ? "#000" : "#fefff6")};
  background-color: ${({ active }) => (active ? "#fefff6" : "#000")};
  padding: 1vh 2vw;
  text-decoration: none;
  font-size: 1.5vw;
  font-family: "Igra Sans";
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;

  ${({ active }) =>
    !active &&
    css`
      &:hover {
        color: #40e0d0;
        background-color: #fefff6;
      }
    `}

  ${media.pure.less(media.size.sm)} {
    font-size: unset !important;
  }
`;
