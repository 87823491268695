import { Header } from "./features/header";
import { Router } from "pages/router";
import React from "react";
import styled from "styled-components";
import "./index.css";

const App = () => {
  return (
    <Wrapper>
      <Header />
      {/* <ErrorBoundary> */}
      <Router />
      {/* </ErrorBoundary> */}
    </Wrapper>
  );
};

export default App;

const Wrapper = styled.div``;
