import React from "react";
import ReactDOM from "react-dom/client";
import { Router } from "react-router-dom";
import { history } from "./libs/history";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("app-root") as any);

const run = async () => {
  root.render(
    // @ts-ignore
    <Router history={history}>
      <App />
    </Router>
  );
};

run();
