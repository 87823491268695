import qs, { StringifyOptions } from "query-string";
import { insertHash } from "./_url";
import { AnyObject } from "../../types";

function withHash<T = AnyObject>(
  hash: T,
  settings?: StringifyOptions
): (url: string) => string;
function withHash<T = AnyObject>(
  url: string,
  hash: T,
  settings?: StringifyOptions
): string;
/**
 *
 * @example
 * basic
 * withHash('/api/wow', {a:1}) => '/api/wow?a=1'
 * @example
 * curry
 * withUrl({a:1})('/api/wow') => '/api/wow?a=1'
 */
function withHash<T = AnyObject>(a: string | AnyObject, b?: T, c?: any) {
  if (typeof a === "string") {
    //@ts-ignore
    const p = qs.stringify(b, c);
    return insertHash(a, p);
  }
  return (c: string) => {
    //@ts-ignore
    const p = qs.stringify(a, b);
    return insertHash(c, p);
  };
}
export { withHash };
