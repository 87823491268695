import importedComponent from "react-imported-component";
import Swal from "sweetalert2";

export const lazyLoad = (a: any, b?: any) =>
  importedComponent(fn(a), {
    LoadingComponent: b,
  });

const fn = (cb: any, tries = 3) => {
  return async () => {
    try {
      const mod = await cb();

      return mod;
    } catch (exception) {
      if (tries > 0) {
        return await new Promise((res) =>
          setTimeout(async () => {
            res(await fn(cb, tries - 1)());
          }, 3000)
        );
      }
      const flag = JSON.parse(
        window.localStorage.getItem("page-has-been-force-refreshed") || "false"
      );
      //@ts-ignore
      if (exception.name === "ChunkLoadError" && !window.navigator.onLine) {
        await Swal.fire({
          type: "warning",
          title: "Ошибка интернет соединения",
          text: `Ваше интернет соединение нестабильно, попробуйте позже!`,
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: "Обновить",
        }).then((result) => {
          if (result.value) {
            window.location.reload();
          }
        });
      }

      if (!flag) {
        window.localStorage.setItem("page-has-been-force-refreshed", "true");
        Swal.fire({
          type: "info",
          title: "Обновление",
          text: `Приложение устарело. Требуется обновление!`,
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: "Обновить",
        }).then((result) => {
          if (result.value) {
            window.localStorage.setItem(
              "page-has-been-force-refreshed",
              "false"
            );
            window.location.reload();
          }
        });

        return;
      }

      throw exception;
    }
  };
};
