import { isDevelopment } from "../dev";

type Settings = {
  onlyDev?: boolean;
  defaultPrefix?: string;
  prefix?: string;
  disableWindow?: boolean;
  disableEnv?: boolean;
  envName?: string;
  windowName?: string;
};

export const imgPath = (path: string, settings: Settings) => {
  const {
    onlyDev = true,
    prefix = "",
    disableWindow = false,
    disableEnv = false,
    envName = "IMG_PATH_ENV",
    windowName = "img_path_window",
    defaultPrefix = "https://homoludens.ru",
  } = settings;
  const envPrefix = !disableEnv && process.env[envName];
  const windowPrefix = !disableWindow && process.env[windowName];

  const url = () => {
    if (isFullUrl(path)) return path;
    if (prefix) return makeUrl(path, prefix);
    if (envPrefix) return makeUrl(path, envPrefix);
    if (windowPrefix) return makeUrl(path, windowPrefix);
    return makeUrl(path, defaultPrefix);
  };

  if (onlyDev && !isDevelopment) return path;
  return url();
};

const isFullUrl = (url: string) => {
  return (
    !!url.match(/https?/g) &&
    !!url.match(
      /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g
    )
  );
};

const makeUrl = (url: string, prefix: string) => {
  if (isFullUrl(url)) return url;
  return `${prefix}${url}`;
};
