import React, { Suspense } from "react";
import { lazyLoad } from "../../libs/lazy-load/lazy-load";

const Main = lazyLoad(
  () => /* webpackChunkName: "r.profile" */ import("./main")
);

const MainWrapper = () => {
  return (
    <Suspense fallback={null}>
      <Main />
    </Suspense>
  );
};

export const mainPaths = {
  main: "/",
};

export const mainRoute = [
  {
    path: mainPaths.main,
    component: MainWrapper,
    exact: true,
  },
];
