import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import React from "react";
import { mainRoute } from "pages/main";
import { createRouter } from "libs/create-router/create-router";

export const Routes = createRouter(mainRoute);

export const Router = () => {
  useLocation();
  return (
    // @ts-ignore
    <Switch>
      {Routes.map((val, i) => (
        // @ts-ignore
        <Route
          key={i}
          exact={val.exact ? val.exact : false}
          path={val.path}
          component={val.component}
        />
      ))}
      {/* @ts-ignore */}
      <Redirect to="/404" />
    </Switch>
  );
};
