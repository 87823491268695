declare global {
  namespace NodeJS {
    export interface ProcessEnv {
      NODE_ENV: "development" | "production" | "test";
      REACT_APP_DEV_PARAM?: string;
      REACT_APP_URL?: string;
      REACT_APP_URL_ALWAYS?: string;
    }
  }
}

export const isDevelopment: boolean = process.env.NODE_ENV === "development";
export const isModule = String(process.env.MODULE) === "true";

const checkRouter = () => {
  if (String(process.env.ROUTER) === "false") return false;
  if (!process.env.ROUTER) return true;
  return true;
};

export const isRouter = checkRouter();
export const isModuleName = {
  baseSite: process.env.MODULENAME === "base-site",
};
