import { createBrowserHistory } from "history";
import { createStore, createEvent, createEffect, combine } from "effector";
import qs from "query-string";
import { History } from "./types";

//@ts-expect-error
const basename = window.__routerBasename__;

const history = createBrowserHistory(basename ? { basename } : {});
const $history = createStore<History>({
  ...history.location,
  action: history.action,
});

const $searchParams = $history.map(({ search }) => new URLSearchParams(search));

const changeHistory = createEvent<History>();
$history.on(changeHistory, (_, h) => h);

const $historySearch = $history.map(({ search }) => ({
  ...qs.parse(search),
}));
const $historyHash = $history.map(({ hash }) => ({
  ...qs.parse(hash),
}));
const $historySearchString = $history.map(({ search }) => search);
const $historyHashString = $history.map(({ hash }) => hash);
const $historyPathname = $history.map(({ pathname }) => pathname);

const $historyPrev = createStore([$historyPathname.getState()]);
$historyPrev.on($history, (state, { pathname: p }) => {
  if (p !== state[state.length - 1]) {
    if (state.length >= 5) {
      return [state[1], state[2], state[3], state[4], p];
    }
    return [...state, p];
  }
  return state;
});
const $historyPrevMap = $historyPrev.map((x) => ({
  array: x,
  last: x[x.length - 2],
}));

const $historyPath = combine({
  pathname: $historyPathname,
  search: $historySearch,
  prev: $historyPrevMap,
  searchString: $historySearchString,
  hash: $historyHash,
  hashString: $historyHashString,
});

history.listen((location, action) => {
  changeHistory({ action, ...location });
});

const historyPush = createEffect((path: Parameters<typeof history.push>[0]) => {
  history.push(path);
});

export {
  $history,
  $historyHash,
  $historyHashString,
  $historyPath,
  $historyPathname,
  $historyPrev,
  $historyPrevMap,
  $historySearch,
  $historySearchString,
  $searchParams,
  history,
  historyPush,
};
